export function prepareMenuOptionsForAPI(menuOptions: any[]) {
  if (!menuOptions) return null;

  return menuOptions?.map((menuOption: any) => ({
    name: menuOption.name,
    description: menuOption.description,
    titleColor: menuOption.titleColor,
    titleBgColor: menuOption.titleBgColor,
    products: menuOption.products.map((product: any) => {
      const aftermarketProduct = product.aftermarketProduct ? { ...product.aftermarketProduct } : undefined;

      if (product.markup) {
        delete product.markup.__typename;
      }

      if (aftermarketProduct && aftermarketProduct.__typename) {
        delete aftermarketProduct.productDescriptionInternal;
        delete aftermarketProduct.createdDateTime;
        delete aftermarketProduct.modifiedDateTime;
        delete aftermarketProduct.__typename;

        if (aftermarketProduct && aftermarketProduct.aftermarketProductCatalogDetails) {
          delete aftermarketProduct.aftermarketProductCatalogDetails.__typename;
        }
      }

      return {
        title: product.title,
        cost: Number(product.cost),
        markup: product.markup,
        retailCost: Number(product.retailCost),
        thirdPartyId: product.thirdPartyId,
        proposalProductId: product.proposalProductId,
        aftermarketProductToPartnerLinkId: product.aftermarketProductToPartnerLinkId,
        addons: product?.configuration?.addons ?? [],
        aftermarketProduct,
      };
    }),
  }));
}
