import React from "react";
import { Box, Typography } from "@material-ui/core";
import ReactQuill from "react-quill";

interface Props {
  value: any;
  label: string;
}

export const OptionSummaryDetails = ({ value, label }: Props) => {
  return (
    <Box className="infoItem">
      <Typography component="span" variant="body1" color="textSecondary">
        {label}
      </Typography>

      
      <ReactQuill
        value={value}
        readOnly={true}
        theme="bubble"
      />
    </Box>
  );
};
