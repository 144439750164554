import { FinanceProgramFormValues } from "../types";
import { prepareMenuOptionsForAPI } from "./prepareMenuOptionsForAPI";

export function collectDataForCreateProposal(
  formData: FinanceProgramFormValues,
  menuByTerms: Record<string, any>
): Record<string, unknown> {
  const {
    financeQuote: {
      fee,
      terms,
      amount,
      rateType,
      commonRate,
      downPayment,
      programName,
      specifyRate,
      specifyRates,
      advancePayment,
      markupRate,
      residual,
      paymentCalculationMethod,
      programType,
    },
  } = formData;

  return {
    rateType,
    salesTaxPercentage: 0,
    menu: prepareMenu(menuByTerms),
    financeProgramName: programName,
    financeProgramType: programType.toUpperCase(),
    customerRate: Number(markupRate),
    amountFinanced: Number(amount) ?? 0,
    residualPercentage: Number(residual) ?? 0,
    downPaymentAmount: Number(downPayment) ?? 0,
    paymentPeriodTiming: paymentCalculationMethod,
    numberOfAdvancePayments: Number(advancePayment) ?? 0,
    markups: [{ identifier: "Lender Markup", markupPercentage: 0 }],
    flatFees: fee.map(feeValue => ({ identifier: feeValue.identifier, financed: feeValue.isFinanced, fee: Number(feeValue.fee) })),
    terms: prepareRates({ specifyRate, commonRate, specifyRates, terms }),
  };
}

function prepareMenu(menuByTerms: Record<string, any> = {}) {
  const menuArr = Object.values(menuByTerms);

  if (!menuArr.length) return null;

  return {
    name: menuArr[0].menu.name,
    menuOptions: prepareMenuOptionsForAPI(menuArr[0]?.menu?.menuOptions),
  };
}

function prepareRates({
  terms,
  specifyRate,
  commonRate,
  specifyRates,
}: Pick<FinanceProgramFormValues["financeQuote"], "specifyRates" | "commonRate" | "specifyRate" | "terms">) {
  if (!specifyRate) return terms.map(term => ({ term: Number(term), rate: Number(commonRate) }));
  return specifyRates.map(({ rate, term }) => ({ term: Number(term), rate: Number(rate) }));
}
