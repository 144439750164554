import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Control } from "react-hook-form/dist/types/form";
import { TextField, TextFieldProps } from "@material-ui/core";
import { FieldValues } from "react-hook-form/dist/types/fields";
import NumberFormat from "react-number-format";
import { UseControllerProps } from "react-hook-form/dist/types/controller";

interface Props<FormValues extends FieldValues = FieldValues> {
  label: string;
  control: Control<FormValues>;
  textFieldProps?: TextFieldProps;
  name: UseControllerProps<FormValues>["name"];
}

export const CurrencyInputField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  control,
  textFieldProps = {},
}: Props<FormValues>) => {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <NumberFormat
          customInput={TextField}
          // @ts-expect-error TODO: Update type
          inputProps={{
            ...textFieldProps,
            ref,
            id: name,
            style: { height: "16px" },
            className: classes.input,
            onFocus: () => setIsFocused(true),
            onBlur: () => {
              setIsFocused(false);
              onBlur();
            },
          }}
          prefix="$"
          value={parseFloat(value)}
          onValueChange={({ value }) => {
            onChange(parseFloat(value));
          }}
          onBlur={onBlur}
          thousandSeparator={true}
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          // placeholder="0.00"
          label={label}
          variant="standard"
          InputLabelProps={{
            shrink: (value !== null && value !== undefined) || isFocused,
          }}
        />
      )}
    />
  );
};

const useStyles = makeStyles({
  startAdornment: {
    margin: "0 0 3px 0",
    "& .MuiSvgIcon-root": {
      fontSize: "1.18rem",
      margin: 0,
    },
  },
  input: {
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});
