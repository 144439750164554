import { UseFormSetValue } from "react-hook-form";
import { MarkupType, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { CommonMenuPriceValues } from "../types";

type Field = "cost" | "markup.markup" | "retailCost";

interface Values {
  cost: number;
  markup: number;
  retailCost: number;
  markupType: MarkupType;
}

function getMarkupValue(markupType: MarkupType, markup: number) {
  return markupType === MarkupType.Percentage ? markup / 100 : markup;
}

export function proposalProductUpdateDependentFields(
  field: Field,
  setter: UseFormSetValue<CommonMenuPriceValues>,
  nextValue: number,
  proposalProduct: ProposalProduct,
  values: Values
) {
  setter(field, nextValue);

  const { cost, retailCost, markup, markupType } = values;

  const markupValue = getMarkupValue(markupType, markup);

  const rules: Record<Field, () => number | null> = {
    cost: () => {
      let retailCostNextValue = 0;

      if (!markupValue && !proposalProduct.retailCost) {
        retailCostNextValue = nextValue;
      } else if (markupValue && !retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = nextValue + nextValue * markupValue;
        } else if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + markupValue;
        }
      } else if (markup && retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = nextValue + nextValue * markupValue;
        } else if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + markupValue;
        }
      } else if (!markup && retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = nextValue + nextValue * markupValue;
        } else if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + markupValue;
        }
      }

      setter("retailCost", retailCostNextValue);

      return retailCostNextValue;
    },
    "markup.markup": () => {
      let retailCostNextValue = 0;

      if (!cost && !retailCost) return null;

      const markupValue = getMarkupValue(markupType, nextValue);

      if (cost && !proposalProduct.retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = cost + cost * markupValue;
        } else if (markupType === MarkupType.Flat) {
          retailCostNextValue = cost + markupValue;
        }
      } else if (!cost && retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = retailCost - nextValue;
        } else if (markupType === MarkupType.Flat) {
          retailCostNextValue = retailCost - nextValue;
        }
      } else if (!nextValue && retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = nextValue + nextValue * markup;
        } else if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + markup;
        }
      }

      setter("retailCost", retailCostNextValue);

      return retailCostNextValue;
    },
    retailCost: () => {
      let markupNextValue = 0;

      if (cost) {
        if (markupType === MarkupType.Percentage) {
          markupNextValue = ((nextValue - cost) / cost) * 100;
        } else if (markupType === MarkupType.Flat) {
          markupNextValue = nextValue - cost;
        }
      }

      setter("markup.markup", markupNextValue);

      return nextValue;
    }
  };

  return rules[field]();
}
