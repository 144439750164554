import React, { PropsWithChildren, ReactNode, useEffect, useMemo } from "react";
import _ from "lodash";
import clsx from "clsx";
import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Controller, useForm, useWatch } from "react-hook-form";
import { blue, blueGrey, grey } from "@material-ui/core/colors";
import { Box, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { MarkupType, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { deskingActions } from "../../../../model";
import { parseCurrency } from "../../../../../../utils";
import { useModal } from "../../../../../../hooks/useModal";
import { markupProposalOptions } from "../../../../constants";
import { CommonMenuPriceValues, DnDTypes } from "../../../../types";
import { proposalProductUpdateDependentFields } from "../../../../lib";
import { ModalsKeys } from "../../../../../../components/shared/Modals";
import { CurrencyInputField, FormRadioGroup, InterestInputField } from "../../../../../../components/form";

interface Props {
  logo?: string;
  type: "simple" | "forEdit";
  proposalProduct: ProposalProduct;
  configuration?: Record<string, any>;
  forcePriceValue: CommonMenuPriceValues;
}

export const ProposalCardContainer = ({
  logo,
  type,
  children,
  proposalProduct,
  forcePriceValue,
  configuration = {},
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.DeskingProductCatalogDialog);

  const dispatch = useDispatch();

  const { control, setValue, watch } = useForm<CommonMenuPriceValues>({
    defaultValues: {
      cost: proposalProduct?.cost,
      retailCost: proposalProduct?.retailCost,
      markup: { markup: proposalProduct.markup?.markup, type: MarkupType.Flat },
    },
  });

  const price = useWatch<CommonMenuPriceValues>({ control });

  useEffect(() => {
    setValue("cost", forcePriceValue?.cost ?? 0);
    setValue("retailCost", forcePriceValue?.retailCost ?? 0);
    setValue("markup.markup", forcePriceValue?.markup?.markup ?? 0);
    setValue("markup.type", forcePriceValue?.markup?.type ?? MarkupType.Flat);
  }, [forcePriceValue]);

  useEffect(() => {
    if (type !== "forEdit") return;

    const handleUpdateProductInMenu = (field: string, value: number | null) => {
      if (value === null) return;

      dispatch(
        deskingActions.updateMenuProduct({
          productId: proposalProduct.proposalProductId,
          field,
          value,
        })
      );
    };

    const subscription = watch((value, { name, type }) => {
      if (!name || type !== "change") return;

      handleUpdateProductInMenu(name, _.get(value, name, null) as number | null);
    });

    return subscription.unsubscribe;
  }, [forcePriceValue, dispatch, type]);

  const productWithConfiguration = useMemo(() => {
    return {
      ...proposalProduct,
      configuration,
      cost: Number(price.cost),
      retailCost: Number(price.retailCost),
      markup: { type: price.markup?.type, markup: Number(price.markup?.markup) },
    };
  }, [proposalProduct, price, configuration]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DnDTypes.ProposalProduct,
      item: productWithConfiguration,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [productWithConfiguration]
  );

  const handleChangeCost = (nextCost: number, forceMarkupType?: MarkupType) => {
    const cost = price.cost!;
    const markup = price.markup?.markup!;
    const retailCost = price.retailCost!;
    const markupType = forceMarkupType ?? price.markup?.type!;

    const newValue = proposalProductUpdateDependentFields("cost", setValue, nextCost, proposalProduct, {
      cost,
      markup,
      markupType,
      retailCost,
    });

    if (type === "forEdit" && newValue) {
      dispatch(
        deskingActions.updateMenuProduct({
          productId: proposalProduct.proposalProductId,
          field: "retailCost",
          value: newValue,
        })
      );
    }
  };

  const handleChangeMarkup = (nextMarkup: number, forceMarkupType?: MarkupType) => {
    const cost = Number(price.cost!);
    const markup = price.markup?.markup!;
    const retailCost = price.retailCost!;
    const markupType = forceMarkupType ?? price.markup?.type!;

    const newValue = proposalProductUpdateDependentFields("markup.markup", setValue, nextMarkup, proposalProduct, {
      cost,
      markup,
      markupType,
      retailCost,
    });

    if (type === "forEdit" && newValue) {
      dispatch(
        deskingActions.updateMenuProduct({
          productId: proposalProduct.proposalProductId,
          field: "retailCost",
          value: newValue,
        })
      );
    }
  };

  const handleChangeRetailCost = (nextRetailCost: number, forceMarkupType?: MarkupType) => {
    const cost = Number(price.cost!);
    const markup = price.markup?.markup!;
    const retailCost = price.retailCost!;
    const markupType = forceMarkupType ?? price.markup?.type!;

    const newValue = proposalProductUpdateDependentFields("retailCost", setValue, nextRetailCost, proposalProduct, {
      cost,
      markup,
      markupType,
      retailCost,
    });

    if (type === "forEdit" && newValue) {
      dispatch(
        deskingActions.updateMenuProduct({
          productId: proposalProduct.proposalProductId,
          field: "retailCost",
          value: newValue,
        })
      );
    }
  };

  const handleChangeMarkupType = (nextType: MarkupType) => {
    setValue("markup.type", nextType);

    const markupValue = price.markup?.markup!;
    const costValue = Number(price.cost!);

    handleChangeCost(costValue, nextType);
    handleChangeMarkup(markupValue, nextType);
  };

  const markupField: Record<MarkupType, ReactNode> = {
    [MarkupType.Flat]: (
      <CurrencyInputField
        label="Markup ($)"
        control={control}
        name="markup.markup"
        textFieldProps={{
          onChange: event => handleChangeMarkup(Number(parseCurrency(event.target.value))),
        }}
      />
    ),
    [MarkupType.Percentage]: (
      <InterestInputField
        label="Markup (%)"
        control={control}
        name="markup.markup"
        textFieldProps={{
          onChange: event => handleChangeMarkup(parseFloat(event.target.value)),
        }}
      />
    ),
  };

  return (
    <Box
      className={clsx(classes.card, {
        [classes.draggingCard]: isDragging,
        [classes.editCard]: type === "forEdit",
      })}
    >
      <Box className={classes.titleAndLogo} {...({ ref: drag } as any)}>
        {logo && <img className={classes.productLogo} src={logo} alt="Proposal product logo" />}

        <Box className={classes.titleContainer}>
          <Box className="title">
            <IconButton size="small" className="dndIcon">
              <DragHandleIcon />
            </IconButton>

            <Typography className="productName" variant="h6">
              {proposalProduct.title}
            </Typography>
          </Box>

          <Tooltip title="Click to see product details">
            <IconButton
              onClick={() => {
                handleOpen({ proposalProduct });
              }}
            >
              <InfoOutlined color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {children}

      <Box className={classes.price}>
        <CurrencyInputField
          name="cost"
          label="Cost"
          control={control}
          textFieldProps={{
            onChange: event => handleChangeCost(parseCurrency(event.target.value)),
          }}
        />

        <Box>
          {markupField[price.markup?.type!]}

          <Controller
            control={control}
            name="markup.type"
            render={({ field }) => (
              <FormRadioGroup
                row
                options={markupProposalOptions}
                {...field}
                onChange={(event, value) => handleChangeMarkupType(value as MarkupType)}
              />
            )}
          />
        </Box>

        <CurrencyInputField
          control={control}
          name="retailCost"
          label="Retail Price"
          textFieldProps={{
            onChange: event => handleChangeRetailCost(parseCurrency(event.target.value)),
          }}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    display: "flex",
    padding: "12px",
    borderRadius: "2px",
    flexDirection: "column",
    backgroundColor: blueGrey["50"],
  },
  editCard: {
    backgroundColor: blue["50"],
  },
  draggingCard: {
    backgroundColor: grey["50"],
  },
  titleAndLogo: {
    display: "flex",
    flexDirection: "column",

    "&:hover": {
      cursor: "grab",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& .title": {
      gap: "6px",
      display: "flex",
      alignItems: "center",
    },
  },
  price: {
    gap: "4px",
    display: "flex",

    "& > *": {
      flex: 1,
    },
  },
  productLogo: {
    width: "48px",
    height: "48px",
  },
});
